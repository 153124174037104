import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  CircularProgress,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";
import { CSVDownload, CSVLink } from "react-csv";
import csvLogo from "../../assets/images/csv-svgrepo-com.svg";
import _ from 'lodash'; // For deep cloning
import Multiselect from 'multiselect-react-dropdown';



NoDataToDisplay(Highcharts);

//redux
import { useDispatch, useSelector } from "react-redux";
import { SpAPISummary } from "../../redux/slices/dashboard-slice";
import { GetStores } from "../../redux/slices/store-slice";
// components
import DateRange from "../../components/date-range";
import Select from "../../components/select";
import DashboardWrapper from "./style";
import { FormatNumber } from "../../utils";
import Catalog from "../../components/catalog";

const styles = {
  advSection: {
    // border: '2px solid #bababa',
    borderRadius: "1%",
    paddingBottom: "5px",
  },
  spApiSection: {
    display: "flex",
    float: "right",
  },
};

const marketPlaces = [
  { label: "Amazon.com", value: "ATVPDKIKX0DER" },
  { label: "Amazon.ca", value: "A2EUQ1WTGCTBG2" },
  { label: "Amazon.com.mx", value: "A1AM78C64UM0Y8" },
  { label: "Amazon.com.br", value: "A2Q3Y263D00KWC" },
  { label: "Amazon.co.uk", value: "A1F83G8C2ARO7P" },
  { label: "Amazon.de", value: "A1PA6795UKMFR9" },
  { label: "Amazon.fr", value: "A13V1IB3VIYZZH" },
  { label: "Amazon.it", value: "APJ6JRA9NG5V4" },
  { label: "Amazon.es", value: "A1RKKUPIHCS9HS" },
  { label: "Amazon.nl", value: "A1805IZSGTT6HS" },
  { label: "Amazon.se", value: "A2NODRKZP88ZB9" },
  { label: "Amazon.pl", value: "A1C3SOZRARQ6R3" },
  { label: "Amazon.tr", value: "A33AVAJ2PDY3EV" },
  { label: "Amazon.ae", value: "A2VIGQ35RCS4UG" },
  { label: "Amazon.in", value: "A21TJRUUN4KGV" },
  { label: "Amazon.sg", value: "A19VAU5U5O7RUS" },
  { label: "Amazon.au", value: "A39IBJ37TRP1C6" },
  { label: "Amazon.jp", value: "A1VC38T7YXB528" },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const Index = () => {
  const dispatch = useDispatch();
  const [CSVData, setCSVData] = useState([]);
  const spApiGraph = useRef(null);
  const spApiGraph1 = useRef(null);
  const spApiGraph2 = useRef(null);
  const spApiGraph3 = useRef(null);
  const spApiGraph4 = useRef(null);
  const start = moment("01/01/2023").calendar();
  const end = moment(new Date()).format("l");
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [selectedStore, setSelectedStore] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [marketplaceIdsList, setmarketPlaceIdsList] = useState(marketPlaces);

  const [selectedMarketplace, setSelectedMarketplace] = useState("");
  const [selectedAsins, setSelectedAsins] = useState([]);
  const [asins, setAsins] = useState([]);


  const [spApiChartOption, setSpApiChart] = useState([]);
  const [spApiChartOptionPPC, setSpApiChartPPC] = useState([]);
  const [spApiChartOptionOrganic, setSpApiChartOrganic] = useState([]);
  const [chart1, setChart1] = useState([]);


  const [spApiChartOption1, setSpApiChart1] = useState([]);
  const [spApiChartOption1PPC, setSpApiChart1PPC] = useState([]);
  const [spApiChartOption1Organic, setSpApiChart1Organic] = useState([]);
  const [chart2, setChart2] = useState([]);


  const [spApiChartOption2, setSpApiChartOption2] = useState([]);
  const [spApiChartOption2PPC, setSpApiChartOption2PPC] = useState([]);
  const [spApiChartOption2Organic, setSpApiChartOption2Organic] = useState([]);
  const [chart3, setChart3] = useState([]);

  const [spApiChartOption3, setSpApiChartOption3] = useState([]);
  const [spApiChartOption3PPC, setSpApiChartOption3PPC] = useState([]);
  const [spApiChartOption3Organic, setSpApiChartOption3Organic] = useState([]);
  const [chart4, setChart4] = useState([]);

  const [chart5, setChart5] = useState([]);


  const [catalogs, setCatalogs] = useState([]);


  const [conversionChecked, setConversionChecked] = useState(["total"]);
  const [revenue, setRevenue] = useState(["total"]);
  const [sessionsChecked, setSessionsChecked] = useState(["total"]);
  const [order, setOrder] = useState(["total"]);

  const handleChange = (event) => {
    setConversionChecked(prev => {
      var temp = _.cloneDeep(prev)
      const idx = temp.findIndex(val => val === event);
      if(idx >= 0)
        temp.splice(idx, 1)
      else
        temp.push(event)

      return temp

    });
  };
  const handleSetRevenue = (event) => {
    setRevenue(prev => {
      var temp = _.cloneDeep(prev)
      const idx = temp.findIndex(val => val === event);
      if(idx >= 0)
        temp.splice(idx, 1)
      else
        temp.push(event)

      return temp

    });
  };

  const handleSetOrder = (event) => {
    setOrder(prev => {
      var temp = _.cloneDeep(prev)
      const idx = temp.findIndex(val => val === event);
      if(idx >= 0)
        temp.splice(idx, 1)
      else
        temp.push(event)

      return temp

    });
  };

  const handleSetSessions = (event) => {
    setSessionsChecked(prev => {
      var temp = _.cloneDeep(prev)
      const idx = temp.findIndex(val => val === event);
      if(idx >= 0)
        temp.splice(idx, 1)
      else
        temp.push(event)

      return temp

    }); 
  };

  const {
    spApiSummary,

    loading,
  } = useSelector((state) => state.dashboard);

  const { stores } = useSelector((state) => state.store);

  useEffect(() => {
/* 
    switch(conversionChecked){
      case 'ppc':
        setChart1(_.cloneDeep(spApiChartOptionPPC))
        break;
      case 'organic':
        setChart1(_.cloneDeep(spApiChartOptionOrganic))
        break;
      case 'total':
        setChart1(_.cloneDeep(spApiChartOption))
        break;
    }
 */
    if(conversionChecked.includes('ppc') && conversionChecked.includes('organic') && conversionChecked.includes('total')){
      setChart1([
        ..._.cloneDeep(spApiChartOptionPPC), 
        ..._.cloneDeep(spApiChartOptionOrganic), 
        ..._.cloneDeep(spApiChartOption)
      ])
    }
    else if(conversionChecked.includes('ppc') && conversionChecked.includes('organic'))
    {
      setChart1([
        ..._.cloneDeep(spApiChartOptionPPC), 
        ..._.cloneDeep(spApiChartOptionOrganic)
      ])
    }
    else if(conversionChecked.includes('ppc') && conversionChecked.includes('total'))
    {
      setChart1([
        ..._.cloneDeep(spApiChartOptionPPC), 
        ..._.cloneDeep(spApiChartOption)
      ])
    }
    else if(conversionChecked.includes('organic') && conversionChecked.includes('total'))
    {
      setChart1([
        ..._.cloneDeep(spApiChartOptionOrganic), 
        ..._.cloneDeep(spApiChartOption)
      ])
    }
    else if(conversionChecked.includes('organic'))
    {
      setChart1(_.cloneDeep(spApiChartOptionOrganic))
    }
    else if(conversionChecked.includes('ppc'))
    {
      setChart1(_.cloneDeep(spApiChartOptionPPC))
    }
    else if(conversionChecked.includes('total'))
    {
      setChart1(_.cloneDeep(spApiChartOption))
    }
    else
    {
      setChart1([])
    }


  }, [spApiChartOption, spApiChartOptionOrganic, spApiChartOptionPPC, conversionChecked]);

  useEffect(() => {/* 
    switch(revenue){
      case 'ppc':
        setChart2(_.cloneDeep(spApiChartOption1PPC))
        break;
      case 'organic':
        setChart2(_.cloneDeep(spApiChartOption1Organic))
        break;
      case 'total':
        setChart2(_.cloneDeep(spApiChartOption1))
        break;
    } */


    if(revenue.includes('ppc') && revenue.includes('organic') && revenue.includes('total')){
      setChart2([
        ..._.cloneDeep(spApiChartOption1PPC), 
        ..._.cloneDeep(spApiChartOption1Organic), 
        ..._.cloneDeep(spApiChartOption1)
      ])
    }
    else if(revenue.includes('ppc') && revenue.includes('organic'))
    {
      setChart2([
        ..._.cloneDeep(spApiChartOption1PPC), 
        ..._.cloneDeep(spApiChartOption1Organic)
      ])
    }
    else if(revenue.includes('ppc') && revenue.includes('total'))
    {
      setChart2([
        ..._.cloneDeep(spApiChartOption1PPC), 
        ..._.cloneDeep(spApiChartOption1)
      ])
    }
    else if(revenue.includes('organic') && revenue.includes('total'))
    {
      setChart2([
        ..._.cloneDeep(spApiChartOption1Organic), 
        ..._.cloneDeep(spApiChartOption1)
      ])
    }
    else if(revenue.includes('organic'))
    {
      setChart2(_.cloneDeep(spApiChartOption1Organic))
    }
    else if(revenue.includes('ppc'))
    {
      setChart2(_.cloneDeep(spApiChartOption1PPC))
    }
    else if(revenue.includes('total'))
    {
      setChart2(_.cloneDeep(spApiChartOption1))
    }
    else
    {
      setChart2([])
    }

  }, [spApiChartOption1, spApiChartOption1PPC, spApiChartOption1Organic,revenue]);


  useEffect(() => {/* 
    switch(revenue){
      case 'ppc':
        setChart2(_.cloneDeep(spApiChartOption1PPC))
        break;
      case 'organic':
        setChart2(_.cloneDeep(spApiChartOption1Organic))
        break;
      case 'total':
        setChart2(_.cloneDeep(spApiChartOption1))
        break;
    } */


    if(order.includes('ppc') && order.includes('organic') && order.includes('total')){
      setChart4([
        ..._.cloneDeep(spApiChartOption3PPC), 
        ..._.cloneDeep(spApiChartOption3Organic), 
        ..._.cloneDeep(spApiChartOption3)
      ])
    }
    else if(order.includes('ppc') && order.includes('organic'))
    {
      setChart4([
        ..._.cloneDeep(spApiChartOption3PPC), 
        ..._.cloneDeep(spApiChartOption3Organic)
      ])
    }
    else if(order.includes('ppc') && order.includes('total'))
    {
      setChart4([
        ..._.cloneDeep(spApiChartOption3PPC), 
        ..._.cloneDeep(spApiChartOption3)
      ])
    }
    else if(order.includes('organic') && order.includes('total'))
    {
      setChart4([
        ..._.cloneDeep(spApiChartOption3Organic), 
        ..._.cloneDeep(spApiChartOption3)
      ])
    }
    else if(order.includes('organic'))
    {
      setChart4(_.cloneDeep(spApiChartOption3Organic))
    }
    else if(order.includes('ppc'))
    {
      setChart4(_.cloneDeep(spApiChartOption3PPC))
    }
    else if(order.includes('total'))
    {
      setChart4(_.cloneDeep(spApiChartOption3))
    }
    else
    {
      setChart4([])
    }

  }, [spApiChartOption1, spApiChartOption1PPC, spApiChartOption1Organic,order]);


  useEffect(() => {/* 
    switch(sessionsChecked){
      case 'ppc':
        setChart3(_.cloneDeep(spApiChartOption2PPC))
        break;
      case 'organic':
        setChart3(_.cloneDeep(spApiChartOption2Organic))
        break;
      case 'total':
        setChart3(_.cloneDeep(spApiChartOption2))
        break;
    } */

    if(sessionsChecked.includes('ppc') && sessionsChecked.includes('organic') && sessionsChecked.includes('total')){
      setChart3([
        ..._.cloneDeep(spApiChartOption2PPC), 
        ..._.cloneDeep(spApiChartOption2Organic), 
        ..._.cloneDeep(spApiChartOption2)
      ])
    }
    else if(sessionsChecked.includes('ppc') && sessionsChecked.includes('organic'))
    {
      setChart3([
        ..._.cloneDeep(spApiChartOption2PPC), 
        ..._.cloneDeep(spApiChartOption2Organic)
      ])
    }
    else if(sessionsChecked.includes('ppc') && sessionsChecked.includes('total'))
    {
      setChart3([
        ..._.cloneDeep(spApiChartOption2PPC), 
        ..._.cloneDeep(spApiChartOption2)
      ])
    }
    else if(sessionsChecked.includes('organic') && sessionsChecked.includes('total'))
    {
      setChart3([
        ..._.cloneDeep(spApiChartOption2Organic), 
        ..._.cloneDeep(spApiChartOption2)
      ])
    }
    else if(sessionsChecked.includes('organic'))
    {
      setChart3(_.cloneDeep(spApiChartOption2Organic))
    }
    else if(sessionsChecked.includes('ppc'))
    {
      setChart3(_.cloneDeep(spApiChartOption2PPC))
    }
    else if(sessionsChecked.includes('total'))
    {
      setChart3(_.cloneDeep(spApiChartOption2))
    }
    else
    {
      setChart3([])
    }

  }, [spApiChartOption2, spApiChartOption2Organic, spApiChartOption2PPC, sessionsChecked]);

  useEffect(() => {
    dispatch(
      GetStores({
        searchKeyWord: "",
        skip: 0,
        limit: 10,
      })
    );
  }, []);

  useEffect(() => {

    if(spApiSummary.catalogs && selectedAsins){
      setCatalogs(prev => {
        return spApiSummary
        .catalogs
        .filter(x => 0 <= selectedAsins.findIndex(y => y.id === x.asin))
      })
    }

  },[spApiSummary, selectedAsins])

  useEffect(() => {

    if (spApiSummary?.salesByDate && spApiSummary?.salesByDate[0]) {

      console.log(spApiSummary.catalogs)

      let childAsinsList = spApiSummary.salesByDate
                    .reduce((acc, val) => {
                      // Only add the first occurrence of each unique childAsin
                      if (!acc.some(item => item.id === val.childAsin)) {
                        acc.push({name: val.childAsin, id: val.childAsin, type: 'child'});
                      }
                      return acc;
                    }, [])

      let parentAsinsList = spApiSummary.salesByDate
                    .reduce((acc, val) => {
                      // Only add the first occurrence of each unique childAsin
                      if (!acc.some(item => item.id === val.parentAsin)) {
                        acc.push({name: val.parentAsin, id: val.parentAsin, type: 'parent'});
                      }
                      return acc;
                    }, [])


      const asinsList = [...childAsinsList, ... parentAsinsList]

      setAsins(asinsList)

      let firstAsin = asinsList[0]

      setSelectedAsins([firstAsin])

    }
    else
    {
      setAsins([])
      setSelectedAsins([])
    }

  },[spApiSummary])

  useEffect(() => {
    if (spApiSummary?.salesByDate && spApiSummary?.salesByDate[0] && selectedAsins) {
      const obj = Object.keys(spApiSummary?.salesByDate[0]);


      // conversions
      let objects = obj.filter((i) => i === "all_conversion");

      let total = spApiSummary.salesByDate.filter(i => i.type === 'total' && 0 <= selectedAsins.findIndex(asin => asin.name === i.childAsin || asin.name === i.parentAsin))
      let ppc = spApiSummary.salesByDate.filter(i => i.type === 'ppc' && 0 <= selectedAsins.findIndex(asin => asin.name === i.childAsin || asin.name === i.parentAsin))
      let organic = spApiSummary.salesByDate.filter(i => i.type === 'organic' && 0 <= selectedAsins.findIndex(asin => asin.name === i.childAsin || asin.name === i.parentAsin))

      /* setSpApiChart(
        spApiSummary?.salesByDate
          .map((item, index) => {
            if (item && objects.length > index) {
              return {
                name: "Conversions",
                data: spApiSummary.salesByDate.map((i) => [
                  new Date(i._id.date).getTime(),
                  parseInt(i[objects[index % objects.length]]),
                ]),
              };
            }
          })
          .filter((i) => (i ? true : false))
      ); 

      setSpApiChart(
        total
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Conversion for asin ${val.childAsin}`,
            data: total.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.conversionRate),
              ]
            ),
          }
        }
        )
      );
*/

    console.log(ppc)

    setChart5(
      selectedAsins.map((asin, i) => {

        if(asin.type === 'child')
          return {
            name: `Total CTR for child asin ${asin.name}`,
            data: ppc
            .filter(f => f.childAsin === asin.name)
            .map((i) => [
                new Date(i.date).getTime(),
                +parseFloat(i.clickThroughRate).toFixed(2),
              ]
            ),
          }
        else if(asin.type === 'parent')
          return {
            name: `Total CTR for parent asin ${asin.name}`,
            data: ppc
            .filter(f => f.parentAsin === asin.name)
            .reduce((acc, val) => {
              const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
              if(idx >= 0){
                acc[idx].clickThroughRate += val.clickThroughRate
                acc[idx].count += 1
              }
              else
              {
                acc.push({
                  date: val.date,
                  clickThroughRate: val.clickThroughRate,
                  parentAsin: val.parentAsin,
                  count: 1
                })
              }

              return acc

            },[])
            .map((i) => [
                new Date(i.date).getTime(),
                +parseFloat(i.clickThroughRate / i.count).toFixed(2),
              ]
            ),
          }
        }
      )
    )

    setSpApiChart(
      selectedAsins.map((asin, i) => {

          if(asin.type === 'child')
            return {
              name: `Total Conversion for child asin ${asin.name}`,
              data: total
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Total Conversion for parent asin ${asin.name}`,
              data: total
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].conversionRate += val.conversionRate
                  acc[idx].count += 1
                }
                else
                {
                  acc.push({
                    date: val.date,
                    conversionRate: val.conversionRate,
                    parentAsin: val.parentAsin,
                    count: 1
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate / i.count).toFixed(2),
                ]
              ),
            }
        }
      )
    );


      objects = obj.filter((i) => i === "ppc_conversion");

      /* setSpApiChartPPC(
        spApiSummary?.salesByDate
          .map((item, index) => {
            if (item && objects.length > index) {
              return {
                name: "Conversions",
                data: spApiSummary.salesByDate.map((i) => [
                  new Date(i._id.date).getTime(),
                  parseInt(i[objects[index % objects.length]]),
                ]),
              };
            }
          })
          .filter((i) => (i ? true : false))
      ); */

      setSpApiChartPPC(
        selectedAsins.map((asin, i) => {
            if(asin.type === 'child')
            return {
              name: `PPC Conversion for child asin ${asin.name}`,
              data: ppc
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `PPC Conversion for parent asin ${asin.name}`,
              data: ppc
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].conversionRate += val.conversionRate
                  acc[idx].count += 1

                }
                else
                {
                  acc.push({
                    date: val.date,
                    conversionRate: val.conversionRate,
                    parentAsin: val.parentAsin,
                    count: 1
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate / i.count).toFixed(2),
                ]
              ),
            }
          }
        )
      )

      setSpApiChartOrganic(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `Organic Conversion for child asin ${asin.name}`,
              data: organic
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Organic Conversion for parent asin ${asin.name}`,
              data: organic
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].conversionRate += val.conversionRate
                  acc[idx].count += 1
                }
                else
                {
                  acc.push({
                    date: val.date,
                    conversionRate: val.conversionRate,
                    parentAsin: val.parentAsin,
                    count: 1
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.conversionRate / i.count).toFixed(2),
                ]
              ),
            }
          }
        )
      );
/* 
      setSpApiChartPPC(
        ppc
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Conversion for asin ${val.childAsin}`,
            data: ppc.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                +parseFloat(i.conversionRate),
              ]
            ),
          }
        }
        )
        ); */

      objects = obj.filter((i) => i === "organic_conversion");
      /* setSpApiChartOrganic(
        spApiSummary?.salesByDate
          .map((item, index) => {
            if (item && objects.length > index) {
              return {
                name: "Conversions",
                data: spApiSummary.salesByDate.map((i) => [
                  new Date(i._id.date).getTime(),
                  +parseFloat(i[objects[index % objects.length]]),
                ]),
              };
            }
          })
          .filter((i) => (i ? true : false))
      ); */

/*       setSpApiChartOrganic(
        organic
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Conversion for asin ${val.childAsin}`,
            data: organic.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                +parseFloat(i.conversionRate),
              ]
            ),
          }
        }
        )
        );
 */
      // section 2
      objects = obj.filter((i) => i === "all_sessions");

      setSpApiChartOption2(
          selectedAsins.map((asin, i) => {
            if(asin.type === 'child')
              return {
                name: `Total Session for child asin ${asin.name}`,
                data: total
                .filter(f => f.childAsin === asin.name)
                .map((i) => [
                    new Date(i.date).getTime(),
                    parseInt(i.session),
                  ]
                ),
              }
            else if(asin.type === 'parent')
              return {
                name: `Total Session for parent asin ${asin.name}`,
                data: total
                .filter(f => f.parentAsin === asin.name)
                .reduce((acc, val) => {
                  const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                  if(idx >= 0){
                    acc[idx].session += val.session
                  }
                  else
                  {
                    acc.push({
                      date: val.date,
                      session: val.session,
                      parentAsin: val.parentAsin
                    })
                  }
  
                  return acc
  
                },[])
                .map((i) => [
                    new Date(i.date).getTime(),
                    parseInt(i.session),
                  ]
                ),
              }
          }
        )
      );
/* 
      setSpApiChartOption2(
        total
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Session for asin ${val.childAsin}`,
            data: total.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.session),
              ]
            ),
          }
        }
        )
      );
 */
      // sessions ppc
      objects = obj.filter((i) => i === "ppc_sessions");

      setSpApiChartOption2PPC(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `PPC Session for child asin ${asin.name}`,
              data: ppc
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.session),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `PPC Session for parent asin ${asin.name}`,
              data: ppc
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].session += val.session
                }
                else
                {
                  acc.push({
                    date: val.date,
                    session: val.session,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.session),
                ]
              ),
            }
          }
        )
      );
/* 
      setSpApiChartOption2PPC(
        ppc
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Session for asin ${val.childAsin}`,
            data: ppc.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.session),
              ]
            ),
          }
        }
        )
      );
 */
      objects = obj.filter((i) => i === "organic_sessions");

      setSpApiChartOption2Organic(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `Organic Session for child asin ${asin.name}`,
              data: organic
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.session),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Organic Session for parent asin ${asin.name}`,
              data: organic
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].session += val.session
                }
                else
                {
                  acc.push({
                    date: val.date,
                    session: val.session,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.session),
                ]
              ),
            }
          }
        )
      );

      // ppc organic
      /* setSpApiChartOption2Organic(
        organic
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Session for asin ${val.childAsin}`,
            data: organic.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.session),
              ]
            ),
          }
        }
        )
      ); */

      // section 3

      objects = obj.filter((i) => i === "all_orders" || i === "all_revenue");


      setSpApiChart1(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `Total Revenue for child asin ${asin.name}`,
              data: total
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Total Revenue for parent asin ${asin.name}`,
              data: total
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].totalAmount += val.totalAmount
                }
                else
                {
                  acc.push({
                    date: val.date,
                    totalAmount: val.totalAmount,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          }
        )
      );
/* 
      setSpApiChart1(
        [...total
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Orders for asin ${val.childAsin}`,
            data: total.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.unitsOrdered),
              ]
            ),
          }
        }
        ),
        ...total
        .reduce((acc, val) => {
          // Only add the first occurrence of each unique childAsin
          if (!acc.some(item => item.childAsin === val.childAsin)) {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((val) =>{
          return {
            name: `Revenue for asin ${val.childAsin}`,
            data: total.filter(f => f.childAsin === val.childAsin)
            .map((i) => [
                new Date(i.date).getTime(),
                parseInt(i.totalAmount),
              ]
            ),
          }
        }
        )
      ]
      );
 */
      // ppc orders

      objects = obj.filter((i) => i === "ppc_orders" || i === "ppc_revenue");

      setSpApiChart1PPC(          
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `PPC Revenue for child asin ${asin.name}`,
              data: ppc
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `PPC Revenue for parent asin ${asin.name}`,
              data: ppc
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].totalAmount += val.totalAmount
                }
                else
                {
                  acc.push({
                    date: val.date,
                    totalAmount: val.totalAmount,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          }
        )
      );
/* 
      setSpApiChart1PPC(
        [...ppc
          .reduce((acc, val) => {
            // Only add the first occurrence of each unique childAsin
            if (!acc.some(item => item.childAsin === val.childAsin)) {
              acc.push(val);
            }
            return acc;
          }, [])
          .map((val) =>{
            return {
              name: `Orders for asin ${val.childAsin}`,
              data: ppc.filter(f => f.childAsin === val.childAsin)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
          }
          ),
          ...ppc
          .reduce((acc, val) => {
            // Only add the first occurrence of each unique childAsin
            if (!acc.some(item => item.childAsin === val.childAsin)) {
              acc.push(val);
            }
            return acc;
          }, [])
          .map((val) =>{
            return {
              name: `Revenue for asin ${val.childAsin}`,
              data: ppc.filter(f => f.childAsin === val.childAsin)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.totalAmount),
                ]
              ),
            }
          }
          )
        ]
      );

 */      objects = obj.filter((i) => i === "organic_orders" || i === "organic_revenue");


      setSpApiChart1Organic(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `Organic Revenue for child asin ${asin.name}`,
              data: organic
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Organic Revenue for parent asin ${asin.name}`,
              data: organic
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].totalAmount += val.totalAmount
                }
                else
                {
                  acc.push({
                    date: val.date,
                    totalAmount: val.totalAmount,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  +parseFloat(i.totalAmount).toFixed(2),
                ]
              ),
            }
          }
        )
      );
/* 
      setSpApiChart1Organic(
        [...organic
          .reduce((acc, val) => {
            // Only add the first occurrence of each unique childAsin
            if (!acc.some(item => item.childAsin === val.childAsin)) {
              acc.push(val);
            }
            return acc;
          }, [])
          .map((val) =>{
            return {
              name: `Orders for asin ${val.childAsin}`,
              data: organic.filter(f => f.childAsin === val.childAsin)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
          }
          ),
          ...organic
          .reduce((acc, val) => {
            // Only add the first occurrence of each unique childAsin
            if (!acc.some(item => item.childAsin === val.childAsin)) {
              acc.push(val);
            }
            return acc;
          }, [])
          .map((val) =>{
            return {
              name: `Revenue for asin ${val.childAsin}`,
              data: organic.filter(f => f.childAsin === val.childAsin)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.totalAmount),
                ]
              ),
            }
          }
          )
        ]
      ); */

      setSpApiChartOption3(
        selectedAsins.map((asin, i) => {
            if(asin.type === 'child')
              return {
                name: `Total Orders for child asin ${asin.name}`,
                data: total
                .filter(f => f.childAsin === asin.name)
                .map((i) => [
                    new Date(i.date).getTime(),
                    parseInt(i.unitsOrdered),
                  ]
                ),
              }
            else if(asin.type === 'parent')
              return {
                name: `Total Orders for parent asin ${asin.name}`,
                data: total
                .filter(f => f.parentAsin === asin.name)
                .reduce((acc, val) => {
                  const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                  if(idx >= 0){
                    acc[idx].unitsOrdered += val.unitsOrdered
                  }
                  else
                  {
                    acc.push({
                      date: val.date,
                      unitsOrdered: val.unitsOrdered,
                      parentAsin: val.parentAsin
                    })
                  }

                  return acc

                },[])
                .map((i) => [
                    new Date(i.date).getTime(),
                    parseInt(i.unitsOrdered),
                  ]
                ),
              }
          }
        )
      )

      setSpApiChartOption3PPC(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `PPC Orders for child asin ${asin.name}`,
              data: ppc
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `PPC Orders for parent asin ${asin.name}`,
              data: ppc
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].unitsOrdered += val.unitsOrdered
                }
                else
                {
                  acc.push({
                    date: val.date,
                    unitsOrdered: val.unitsOrdered,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
        }
      )
      )

      setSpApiChartOption3Organic(
        selectedAsins.map((asin, i) => {
          if(asin.type === 'child')
            return {
              name: `Organic Orders for child asin ${asin.name}`,
              data: organic
              .filter(f => f.childAsin === asin.name)
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
          else if(asin.type === 'parent')
            return {
              name: `Organic Orders for parent asin ${asin.name}`,
              data: organic
              .filter(f => f.parentAsin === asin.name)
              .reduce((acc, val) => {
                const idx = acc.findIndex(value => value.date.toString() === val.date.toString() && value.parentAsin === val.parentAsin)
                if(idx >= 0){
                  acc[idx].unitsOrdered += val.unitsOrdered
                }
                else
                {
                  acc.push({
                    date: val.date,
                    unitsOrdered: val.unitsOrdered,
                    parentAsin: val.parentAsin
                  })
                }

                return acc

              },[])
              .map((i) => [
                  new Date(i.date).getTime(),
                  parseInt(i.unitsOrdered),
                ]
              ),
            }
        }
      )
      )

    } else {
      setSpApiChart([]);
      setSpApiChartPPC([]);
      setSpApiChartOrganic([]);
      setSpApiChartOption2([]);
      setSpApiChart1Organic([]);
      setSpApiChart1PPC([]);
      setSpApiChart1([]);
      setSpApiChartOption3([]);
      setSpApiChartOption3Organic([]);
      setSpApiChartOption3PPC([]);
    }

    const tempArr = spApiSummary.salesByDate
      ? spApiSummary?.salesByDate?.map((i) => {
          return Object.keys(i).map((item) => {
            if (item === "date") {
              const date_ = new Date(i[item]);

              const yyyy = date_.getFullYear();
              let mm = date_.getMonth() + 1; // Months start at 0!
              let dd = date_.getDate();

              if (dd < 10) dd = "0" + dd;
              if (mm < 10) mm = "0" + mm;

              const formattedToday = dd + "/" + mm + "/" + yyyy;
              return formattedToday;
            }
            if (item === "totalAmount") {
              return `${i[item]} ${i.currencyCode}`;
            }
            return i[item].toString()
          });
        })
      : [];

    if (Array.isArray(spApiSummary.salesByDate))
      if (spApiSummary.salesByDate.length > 0) {
        setCSVData(
          [
            Object.keys(spApiSummary.salesByDate[0]).map((i) => {

              return camelCaseToWords(i);

            }),
          ].concat(tempArr)
        );
      }
  }, [spApiSummary, selectedAsins]);

  useEffect(() => {
    const tempStores = [];

    if (stores?.length) {
      for (const item of stores) {
        const { name, _id } = item;
        tempStores.push({
          label: name,
          value: _id,
        });
      }
      setStoreList(tempStores);
      setSelectedStore(tempStores[0].value);
    }
  }, [stores]);

  useEffect(() => {
    if (stores && selectedStore) {
      const filteredStores =
        stores.filter((i) => i._id === selectedStore)[0] &&
        stores.filter((i) => i._id === selectedStore)[0];
      const temp = filteredStores
        ? filteredStores.marketplaces.map((i) => {
            return {
              label: i.name,
              value: i.marketplaceId,
            };
          })
        : [];

      const tempArr = temp.map((i) => {
        return marketPlaces.filter((x) => x?.value === i?.value)[0];
      });

      setSelectedMarketplace(tempArr[0] ? tempArr[0].value : "");
      setmarketPlaceIdsList(tempArr);
    }
  }, [selectedStore, stores]);

  const handleDateChange = (value) => {
    const { startDate, endDate } = value;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    dispatch(
      SpAPISummary({
        storeId: selectedStore,
        startDate,
        endDate,
        marketPlaceId: selectedMarketplace,
      })
    );

    !stores &&
      dispatch(
        GetStores({
          searchKeyword: "",
          limit: 100,
          skip: 0,
        })
      );
  }, [selectedMarketplace, startDate, endDate, selectedStore]);

  const camelCaseToWords = (val) => {
    const result = val.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <DashboardWrapper>
      <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={6} container>
            <DateRange handleDateChange={handleDateChange} />

            {spApiSummary?.salesByDate?.length !== 0 && (
              <CSVLink className='csv-link' data={CSVData}>
                <img src={csvLogo} style={{ maxWidth: "20px" }} />
                <span>Download CSV</span>
              </CSVLink>
            )}
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <Grid container>
              <Grid xs={4} item>
                <Select
                  data={storeList}
                  handleChange={(event) => setSelectedStore(event.target.value)}
                  value={selectedStore}
                />
              </Grid>
              <Grid xs={4} item>
                <Select
                  data={marketplaceIdsList}
                  handleChange={(event) =>
                    setSelectedMarketplace(event.target.value)
                  }
                  value={selectedMarketplace}
                />
              </Grid>
              <Grid xs={4} item>
              <Multiselect
                  options={asins}
                  //sx={{ width: 185 }}
                  onSelect={(selectedList, selectedVal) => setSelectedAsins(prev => {
                    return selectedList
                  })} // Function will trigger on select event
                  onRemove={(selectedList, removeVal) => setSelectedAsins(prev => {
                    return selectedList
                  })} // Function will trigger on remove event
                  selectedValues={selectedAsins}
                  displayValue= "name"
                  groupBy="type"
                  placeholder="Select Asin"
                  showCheckbox={true}
                  style={{
                    option: { // To change css for dropdown options
                      fontSize: "12px",
                      padding: "8px"
                      }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 1, display: "flex", flexDirection: "column", flexWrap: "wrap", flexDirection: "row", gap: "32px"}} mt={2}>
        {!!catalogs && catalogs.map((catalog, i) => <Catalog 
        key={i} 
        name={catalog.summaries[0].itemName}
        images={catalog.images[0].images.length > 5 ? catalog.images[0].images.slice(0,1) : catalog.images[0].images.slice(0,1)}
        asin={catalog.asin}
        />)}
      </Box>
      <Box style={styles.advSection} mt={2}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <HighchartsReact
                ref={spApiGraph4}
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "spline",
                  },
                  title: {
                    text: camelCaseToWords("Click Through Rate"),
                  },
                  xAxis: {
                    type: "datetime",

                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        Highcharts.dateFormat("%e-%b-%Y", new Date(this.x)) +
                        "<br></br>" +
                        "conversion: " +
                        this.y +
                        "%"
                      );
                    },
                  },
                  series: chart5,
                }}
            />
            <Box mt={3} mb={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Item>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Box style={styles.tileHeading}>Conversion Rate</Box>
                        <Box style={styles.amount}>
                          {FormatNumber(
                            FormatNumber(
                              conversionChecked === "total"
                                ? spApiSummary?.total_of_all_conversion
                                : conversionChecked === "ppc"
                                ? spApiSummary?.total_of_ppc_conversion
                                : spApiSummary?.total_of_organic_conversion
                            )
                          )}
                        </Box>
                      </Box>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              label='Organic'
              control={
                <Checkbox
                  checked={conversionChecked.includes("organic") ? true : false}
                  onChange={() => handleChange("organic")}
                />
              }
            />
            <FormControlLabel
              label='PPC'
              control={
                <Checkbox
                  checked={conversionChecked.includes("ppc") ? true : false}
                  onChange={() => handleChange("ppc")}
                />
              }
            />
            <FormControlLabel
              label='Total'
              control={
                <Checkbox
                  checked={conversionChecked.includes("total") ? true : false}
                  onChange={() => handleChange("total")}
                />
              }
            />
            <HighchartsReact
              ref={spApiGraph}
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "spline",
                },
                title: {
                  text: camelCaseToWords("conversions"),
                },
                xAxis: {
                  type: "datetime",

                  labels: {
                    format: "{value:%Y-%m-%d}",
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      Highcharts.dateFormat("%e-%b-%Y", new Date(this.x)) +
                      "<br></br>" +
                      "conversion: " +
                      this.y +
                      "%"
                    );
                  },
                },
                series: chart1,
              }}
            />
            <Box mt={3} mb={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Item>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Box style={styles.tileHeading}>Conversion Rate</Box>
                        <Box style={styles.amount}>
                          {FormatNumber(
                            FormatNumber(
                              conversionChecked === "total"
                                ? spApiSummary?.total_of_all_conversion
                                : conversionChecked === "ppc"
                                ? spApiSummary?.total_of_ppc_conversion
                                : spApiSummary?.total_of_organic_conversion
                            )
                          )}
                        </Box>
                      </Box>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              label='Organic'
              control={
                <Checkbox
                  checked={sessionsChecked.includes("organic") ? true : false}
                  onChange={() => handleSetSessions("organic")}
                />
              }
            />
            <FormControlLabel
              label='PPC'
              control={
                <Checkbox
                  checked={sessionsChecked.includes("ppc") ? true : false}
                  onChange={() => handleSetSessions("ppc")}
                />
              }
            />
            <FormControlLabel
              label='Total'
              control={
                <Checkbox
                  checked={sessionsChecked.includes("total") ? true : false}
                  onChange={() => handleSetSessions("total")}
                />
              }
            />
            <HighchartsReact
              ref={spApiGraph1}
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "spline",
                },
                title: {
                  text: camelCaseToWords("Sessions"),
                },
                xAxis: {
                  type: "datetime",
                  labels: {
                    format: "{value:%Y-%m-%d}",
                  },
                },

                series: chart3,
              }}
            />
            <Box mt={3} mb={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Item>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Box style={styles.tileHeading}>Sessions</Box>
                        <Box style={styles.amount}>
                          {FormatNumber(
                            sessionsChecked === "total"
                              ? spApiSummary?.total_sessions
                              : sessionsChecked === "ppc"
                              ? spApiSummary?.total_ppc_sessions
                              : spApiSummary?.total_organic_sessions
                          )}
                        </Box>
                      </Box>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              label='Organic'
              control={
                <Checkbox
                  checked={revenue.includes("organic") ? true : false}
                  onChange={() => handleSetRevenue("organic")}
                />
              }
            />
            <FormControlLabel
              label='PPC'
              control={
                <Checkbox
                  checked={revenue.includes("ppc") ? true : false}
                  onChange={() => handleSetRevenue("ppc")}
                />
              }
            />
            <FormControlLabel
              label='Total'
              control={
                <Checkbox
                  checked={revenue.includes("total") ? true : false}
                  onChange={() => handleSetRevenue("total")}
                />
              }
            />
            <HighchartsReact
              ref={spApiGraph2}
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "spline",
                },
                title: {
                  text: camelCaseToWords("Revenue"),
                },
                xAxis: {
                  type: "datetime",
                  labels: {
                    format: "{value:%Y-%m-%d}",
                  },
                },
                series: chart2,
              }}
            />
            <Box mt={3} mb={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Item>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Box style={styles.tileHeading}>Revenue</Box>
                        <Box style={styles.amount}>
                          {FormatNumber(
                            revenue === "total"
                              ? spApiSummary?.total_orders
                              : revenue === "ppc"
                              ? spApiSummary?.total_ppc_orders
                              : spApiSummary?.total_organic_orders
                          )}
                        </Box>
                      </Box>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              label='Organic'
              control={
                <Checkbox
                  checked={order.includes("organic") ? true : false}
                  onChange={() => handleSetOrder("organic")}
                />
              }
            />
            <FormControlLabel
              label='PPC'
              control={
                <Checkbox
                  checked={order.includes("ppc") ? true : false}
                  onChange={() => handleSetOrder("ppc")}
                />
              }
            />
            <FormControlLabel
              label='Total'
              control={
                <Checkbox
                  checked={order.includes("total") ? true : false}
                  onChange={() => handleSetOrder("total")}
                />
              }
            />
            <HighchartsReact
              ref={spApiGraph3}
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "spline",
                },
                title: {
                  text: camelCaseToWords("Orders"),
                },
                xAxis: {
                  type: "datetime",
                  labels: {
                    format: "{value:%Y-%m-%d}",
                  },
                },
                series: chart4,
              }}
            />
            <Box mt={3} mb={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Item>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Box style={styles.tileHeading}>Orders</Box>
                        <Box style={styles.amount}>
                          {FormatNumber(
                            order === "total"
                              ? spApiSummary?.total_orders
                              : order === "ppc"
                              ? spApiSummary?.total_ppc_orders
                              : spApiSummary?.total_organic_orders
                          )}
                        </Box>
                      </Box>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DashboardWrapper>
  );
};

export default Index;
