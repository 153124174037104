import React, { useEffect, useState } from 'react'
import {
    Typography,
    Grid,
    Box,
    Paper,
    CircularProgress,
    Checkbox,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//constants data
import { Regions } from '../../constants'
import Button from '../../components/button'

import {
    advertisingStoreInfo,
    GetActiveProfiles,
    GetAdvertisingAPIToken,
    setField,
    EditStoreData,
} from '../../redux/slices/store-slice'

const styles = {
    tileHeading: {
        color: 'black',
        fontSize: '14px',
    },
    amount: {
        color: '#070707',
        fontSize: '30px',
    },
    icon: {
        fontSize: '14px',
        color: 'red',
        marginBottom: '-3px',
    },
    percent: {
        fontSize: '12px',
        color: 'red',
    },
    greenIcon: {
        fontSize: '14px',
        color: 'green',
        marginBottom: '-3px',
    },
    greenPercent: {
        fontSize: '12px',
        color: 'green',
    },
    text: {
        fontSize: '12px',
    },
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: 'solid 1px #6aabe3',
    textAlign: 'center',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
}))

const ConnectMarketPlaces = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [state, setState] = useState([])
    const [marketplaces, setMarketplaces] = useState([])

    const {
        spApiRegion,
        storeName,
        storeId,
        storeSuccess,
        activeMarketplaces,
        activeMarketplacesSuccess,
        marketplaceEditSuccess,
        err,
        loading,
    } = useSelector((state) => state.store)

    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        console.log('params', {
            authCode: params.get('code'),
        })
        if (params.get('code')) {
            dispatch(setField({ field: 'storeId', value: '' }))
            dispatch(
                GetAdvertisingAPIToken({
                    authCode: params.get('code'),
                    region: spApiRegion,
                    storeName,
                })
            )
        } else {
            dispatch(
                setField({ field: 'err', value: 'Error in store creation' })
            )
        }

        return () => {
            dispatch(
                advertisingStoreInfo({
                    storeName: '',
                    region: '',
                })
            )
        }
    }, [])

    useEffect(() => {
        if (storeSuccess && storeId && storeId !== '') {
            dispatch(GetActiveProfiles({ storeId }))
        }
    }, [storeId])

    useEffect(() => {
        if (err && err !== '') {
            //history.push('/stores')
        }
    }, [err])

    const handleChange = (market) => {
        
        const profile = state.find((a) => a.profileId === market.profileId)
        if (profile) {
            const filtered = state.filter(
                (a) => a.profileId !== market.profileId
            )
            setState(filtered)
        } else {
            const profilesArray = [...state, market]
            setState(profilesArray)
        }
    }

    useEffect(() => {
        if (marketplaceEditSuccess === true) {
            dispatch(
                setField({ field: 'marketplaceEditSuccess', value: false })
            )
            history.push('/stores')
        }
    }, [marketplaceEditSuccess])

    const handleSubmit = () => {
        dispatch(setField({ field: 'storeSuccess', value: false }))
        dispatch(EditStoreData({ storeId, profiles: state, name: storeName }))
    }

    const regionFromLS = window.localStorage.getItem('store-region')
        ? window.localStorage.getItem('store-region')
        : ''

    useEffect(() => {
        if (activeMarketplacesSuccess && activeMarketplaces) {
            const data = Regions[regionFromLS]
                .filter((marketPlace) =>
                    activeMarketplaces.some(
                        (marketplace) =>
                            marketPlace.marketPlaceId ===
                            marketplace.marketPlaceId
                    )
                )
                .map((market) => market)
            console.log({ data })
        }
    }, [activeMarketplacesSuccess])

    return (
        <React.Fragment>
            <Box>
                <h3>Connect Store</h3>
            </Box>
            <Box
                bgcolor='#fff'
                borderRadius='3'
                height='calc(100vh - 100px)'
                overflow='auto'
                display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <div>
                    {true && (
                        <Grid container style={{ marginTop: '50px' }}>
                            <Grid md={12} item p={5}>
                                <Typography
                                    style={{
                                        color: '#201f1e',
                                        fontSize: '2rem',
                                        fontWeight: 700,
                                    }}
                                >
                                    Select the Amazon Advertising Store you
                                    would like to add
                                </Typography>
                                <Typography>
                                    Select any marketplaces that you sell in and
                                    would like to add to your AMZ account. You
                                    will be able to add additional
                                    regions/marketplaces at anytime.
                                </Typography>
                                {activeMarketplacesSuccess && (
                                    <Box mt={6} mb={6} sx={{ flexGrow: 1 }}>
                                        {activeMarketplaces &&
                                            activeMarketplaces.length === 0 && (
                                                <div>
                                                    No marketplaces found.
                                                </div>
                                            )}

                                        <Grid container spacing={2}>
                                            {activeMarketplaces &&
                                                activeMarketplaces.map(
                                                    (market, index) => (
                                                        <>
                                                            {/* <Box mt={3} mb={3} sx={{ flexGrow: 1 }}> */}
                                                            <Grid md={3} item>
                                                                <Item>
                                                                    <Checkbox
                                                                        checked={
                                                                            state.findIndex(
                                                                                (
                                                                                    a
                                                                                ) =>
                                                                                    a.profileId ===
                                                                                    market.profileId
                                                                            ) >
                                                                            -1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={() =>
                                                                            handleChange(
                                                                                market
                                                                            )
                                                                        }
                                                                        style={{
                                                                            float: 'right',
                                                                        }}
                                                                        size='small'
                                                                        name={
                                                                            market.name
                                                                        }
                                                                        color='primary'
                                                                    />
                                                                    <Box
                                                                        style={
                                                                            styles.tileHeading
                                                                        }
                                                                    >
                                                                        {
                                                                            market.name
                                                                        }
                                                                    </Box>
                                                                    <Box
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                        }}
                                                                        mt={2}
                                                                    >
                                                                        <span>
                                                                            <img
                                                                                src={
                                                                                    Regions[
                                                                                        'all'
                                                                                    ].find(
                                                                                        (
                                                                                            doc
                                                                                        ) =>
                                                                                            doc.marketPlaceId ===
                                                                                            market.marketplaceId
                                                                                    )
                                                                                        ?.image
                                                                                }
                                                                                height='25'
                                                                                width='25'
                                                                            />
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    'inline-block',
                                                                                marginLeft:
                                                                                    '5px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                Regions[
                                                                                    'all'
                                                                                ].find(
                                                                                    (
                                                                                        doc
                                                                                    ) =>
                                                                                        doc.marketPlaceId ===
                                                                                        market.marketplaceId
                                                                                )
                                                                                    ?.domain
                                                                            }
                                                                        </span>
                                                                    </Box>
                                                                </Item>
                                                            </Grid>
                                                        </>
                                                    )
                                                )}
                                        </Grid>
                                    </Box>
                                )}

                                {!activeMarketplacesSuccess && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingTop: '20%',
                                            paddingBottom: '25%',
                                            width: '50%',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <CircularProgress
                                                thickness={1}
                                                size={80}
                                            />
                                        </div>
                                    </div>
                                )}

                                <Box
                                    className='buttons'
                                    display='flex'
                                    columnGap={'20px'}
                                >
                                    <Button
                                        text='Go Back'
                                        className='outlined-matisse'
                                        varient='outlined'
                                    />
                                    <Button
                                        disabled={state.length ? false : true}
                                        text='Connect Amazon'
                                        className='contained '
                                        varient='contained'
                                        onClick={() => handleSubmit()}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    {loading && !storeSuccess && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '20%',
                                paddingBottom: '25%',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <CircularProgress thickness={1} size={80} />
                            </div>
                        </div>
                    )}
                </div>
            </Box>
        </React.Fragment>
    )
}

export default ConnectMarketPlaces
