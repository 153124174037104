import { debounce } from "lodash";
import crypto from "crypto";
import React, { useState, useEffect } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { nanoid } from "nanoid";
import {
  Box,
  Stack,
  Grid,
  Divider,
  IconButton,
  Switch,
  Tooltip,
  Drawer,
  Typography,
} from "@mui/material";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import Button from "../../components/button";
import LabelInput from "../../components/input/label-input";
import Input from "../../components/input";
import Loading from "../../components/loading";
import BasicModal from "../../components/modal";
import SearchBar from "../../components/search";
import Select from "../../components/select/select";
import SimpleTable from "../../components/table/overview/simple-table";
import Toast from "../../components/toast";
import EditStore from "./edit-store";
import VaWrapper from "./style";
import {
  advertisingStoreInfo,
  setField,
  SetState,
  GetStores,
  SearchStores,
  EditStoreData,
  DeleteStore,
  amazonStoreInfo,
  CreateStore,
} from "../../redux/slices/store-slice";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const styles = {
  textSuccess: {
    color: "#0B6623",
  },
  textDanger: {
    color: "#ff0000",
  },
};

const data = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 30,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

const columns = [
  { id: "name", label: "Name", minWidth: 80 },
  { id: "profileName", label: "Profile Name", minWidth: 100 },
  {
    id: "sellerId",
    label: "Merchant / Seller ID",
    minWidth: 100,
  },
  {
    id: "status",
    label: "Advertising Status",
    minWidth: 170,
  },
  {
    id: "spapiStatus",
    label: "SPAPI Status",
    minWidth: 170,
  },
  {
    id: "connect",
    label: "ConnectSPAPI",
    minWidth: 170,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 8px rgba(54, 50, 235, 0.23)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const closeStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 8px rgba(54, 50, 235, 0.23)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function createData(
  name,
  profileName,
  sellerId,
  status,
  spapiStatus,
  connect,
  action
) {
  return { name, profileName, sellerId, status, spapiStatus, connect, action };
}

const Index = ({ history }) => {
  // Stores
  const dispatch = useDispatch();
  const [assignStore, setAssignStore] = useState(false);
  const [addStore, setAddStore] = useState(false);
  const [spapiConnect, setSpapiConnect] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [sidebarStore, setSidebarStore] = useState({});
  const [alert, setAlert] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [deleteId, setDeleteId] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [state, setState] = useState({ right: false });
  const [region, setRegion] = useState("na");
  const [updatedStoreName, setUpdatedStoreName] = useState("");
  const condition = localStorage.getItem("spapi");

  const { stores, err, total, success, loading, message, deleteSuccess } =
    useSelector((state) => state.store) || {};

  // const navigate = useNavigate()

  const ConnectAmazonAdvertisingAPI = (e, storeName, regionParam = null) => {

    let regionTemp = regionParam ? regionParam : region;

    const AdvertisingClientId =
      "amzn1.application-oa2-client.f4af7a72d6f0436e93dcf553ae969fc9";
    const AdvertisingRedirectUri =
      "https://dashboard.gembeacon.com/auth/advertising";
      //"https://gembeacon.imgdoesit.com/auth/advertising";
      //"https://gembeacon-frontend.vercel.app/auth/advertising";
    //const AdvertisingRedirectUri = 'http://localhost:3000/auth/advertising';

    if (!storeName) {
      dispatch(SetState({ field: "err", value: "Store Name is Required." }));
      return;
    } else {
      dispatch(
        advertisingStoreInfo({
          region: regionTemp,
          storeName: storeName,
        })
      );

      dispatch(setField({ field: "storeSuccess", value: false }));
      dispatch(setField({ field: "storeId", value: "" }));
      dispatch(setField({ field: "err", value: "" }));
      dispatch(setField({ field: "marketplaceEditSuccess", value: false }));

      let url;
      if (regionTemp === "eu") {
        url = `https://www.amazon.com/ap/oa?client_id=${AdvertisingClientId}&scope=advertising::campaign_management&response_type=code&redirect_uri=${AdvertisingRedirectUri}&version=beta`;
      } else if (regionTemp === "na") {
        url = `https://www.amazon.com/ap/oa?client_id=${AdvertisingClientId}&scope=advertising::campaign_management&response_type=code&redirect_uri=${AdvertisingRedirectUri}&version=beta`;
      } else {
        url = `https://apac.account.amazon.com/ap/oa?client_id=${AdvertisingClientId}&scope=advertising::campaign_management&response_type=code&redirect_uri=${AdvertisingRedirectUri}&version=beta`;
      }
      window.open(url, "_self");
      console.log(first);
    }
    return;
  };

  const regionFromLS = window.localStorage.getItem("store-region")
    ? window.localStorage.getItem("store-region")
    : "";

  // connect spapi store
  const clientId = "amzn1.sp.solution.bebf3355-b72c-4e68-aaa8-756b33540ffd";
  const redirectUrl = `https://dashboard.gembeacon.com/stores`;
  //const redirectUrl =  "https://gembeacon-frontend.vercel.app/stores";


  useEffect(() => {
    window.localStorage.setItem("store-region", region);
  }, [region]);

  const handleConnect = (regionSpApi) => {
    const state = nanoid(64);
    const payload = {
      state,
      regionSpApi,
    };
    dispatch(amazonStoreInfo(payload));

    let url;
    if (regionSpApi === "eu") {
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${clientId}&state=${state}&version=beta&redirect_uri=${redirectUrl}`;
    } else if (regionSpApi === "na") {
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${clientId}&state=${state}&version=beta&redirect_uri=${redirectUrl}`;
    } else {
      url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=${clientId}&state=${state}&version=beta&redirect_uri=${redirectUrl}`;
    }
    window.open(url, "_self");
    localStorage.setItem("spapi", true);
    localStorage.setItem("regionSpApi", regionSpApi);
  };

  // redirect back
  useEffect(() => {
    dispatch(SetState({ field: "storeSuccess", value: false }));
    dispatch(SetState({ field: "err", value: "" }));
    dispatch(SetState({ field: "storeId", value: "" }));

    const params = new URLSearchParams(location.search);

    if (params.get("selling_partner_id") || state === params.get("state")) {
      dispatch(
        CreateStore({
          authCode: params.get("spapi_oauth_code"),
          sellerId: params.get("selling_partner_id"),
          region: localStorage.getItem("regionSpApi"),
        })
      );
    } else {
      dispatch(SetState({ field: "err", value: "Error in store creation" }));
    }

    return () => {
      dispatch(
        amazonStoreInfo({
          state: "",
          region: "",
          storeId: "",
        })
      );
      dispatch(SetState({ field: "storeSuccess", value: false }));
    };
  }, []);

  const handleDelete = (id) => {
    setModalOpen(true);
    setDeleteId(id);
  };
  const removeStore = () => {
    dispatch(DeleteStore({ storeId: deleteId }));
    setModalOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    dispatch(setField({ field: "editStore", value: false }));
  };

  const handleEdit = (store) => {
    setSidebarStore(store);
    setState({ ...state, ["right"]: true });
  };

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const handleSearch = debounce((value) => {
    dispatch(
      SearchStores({
        searchString: value,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
      })
    );
  }, 500);

  const handleStoreChange = (name) => {
    dispatch(setField({ field: "storeName", value: name }));
    setSidebarStore({
      ...sidebarStore,
      name,
    });
  };

  useEffect(() => {
    if ((success || deleteSuccess) && !err && message) {
      dispatch(
        GetStores({
          searchKeyWord: "",
          skip: (pageNumber - 1) * pageLimit,
          limit: pageLimit,
        })
      );
      setMessage(message);
      setSeverity("success");
      setToastOpen(true);
      dispatch(setField({ field: "message", value: "" }));
    }
  }, [success, deleteSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(
        GetStores({
          searchKeyWord: "",
          skip: (pageNumber - 1) * pageLimit,
          limit: pageLimit,
        })
      );
      setMessage(message);
      setSeverity("success");
      setToastOpen(true);
      dispatch(setField({ field: "message", value: "" }));
    }
  }, [deleteSuccess]);

  const hanldeStoreStep = (store) => {
    dispatch(setField({ field: "storeId", value: store._id }));
    dispatch(setField({ field: "storeCreated", value: true }));
    dispatch(setField({ field: "storeSuccess", value: true }));

    //navigate('/auth/advertising')
    history.push("/auth/advertising");
  };

  const createStoreData = () => {
    const rows = [];
    let pendingStore = false;
    for (let i = 0; i < stores.length; i += 1) {
      const store = stores[i];
      const { marketplaces, _id, spApi, region: storeRegion } = store;
      let { status } = store;
      rows.push(
        createData(
          <b>{store?.name}</b>,
          <Tooltip title={marketplaces[0]?.name} placement='top'>
            <span>
              {marketplaces[0]?.name?.slice(0, 15) || "N/A"}
              {marketplaces[0]?.name?.length > 15 ? "..." : ""}
            </span>
          </Tooltip>,
          marketplaces[0]?.sellerId || "",
          <Tooltip
            title={
              status === "Connected"
                ? ""
                : "Your store connection still in pending"
            }
            placement='top'
          >
            <span
              className={
                status === "Connected" ? "status sucsses" : "status error"
              }
            >
              {status}
            </span>
          </Tooltip>,
          <Tooltip
            title={
              spApi?.status === "Connected"
                ? ""
                : "Your store connection still in pending"
            }
            placement='top'
          >
            <span className={spApi ? "status sucsses" : "status error"}>
              {spApi ? "Connected" : "Not Connected"}
            </span>
          </Tooltip>,
          <Button
            text='+ Connect'
            className='outlined w-50'
            type='submit'
            disabled={spApi ? true : false}
            onClick={() => {
              /* if (!spapiConnect) {
                ConnectAmazonAdvertisingAPI(null, store.name, storeRegion);
              } else {
                setSpapiConnect(true);
                setAddStore(true);
              } */
              handleConnect(storeRegion);
            }}
          />,
          <div style={{ display: "flex" }}>
            {status === "Pending" && (
              <IconButton onClick={() => hanldeStoreStep(store)}>
                <Tooltip
                  title='Click here to complete the remaining step to connect store'
                  placement='top'
                >
                  <ArrowCircleRightIcon />
                </Tooltip>
              </IconButton>
            )}
            {status === "Connected" && (
              <IconButton onClick={() => handleEdit(store)}>
                <Tooltip title='Edit store' placement='top'>
                  <EditIcon />
                </Tooltip>
              </IconButton>
            )}

            <IconButton onClick={() => handleDelete(_id)}>
              <Tooltip title='Delete store' placement='top'>
                <DeleteOutlineIcon />
              </Tooltip>
            </IconButton>
          </div>
        )
      );
    }
    if (pendingStore) {
      setAlert(true);
    } else setAlert(false);
    return rows;
  };

  useEffect(() => {
    dispatch(
      GetStores({
        searchKeyWord,
        skip: (pageNumber - 1) * pageLimit,
        limit: pageLimit,
      })
    );
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    const result = createStoreData();
    setPageLimit(pageLimit);
    setRowData(result);
  }, [stores]);

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleRegionChange = (value) => {
    setRegion(value);
  };

  const handleStoreNameChange = (data) => {
    const { _id: storeId, marketplaces } = data;
    if (sidebarStore?.name != "") {
      dispatch(
        EditStoreData({
          storeId,
          profiles: marketplaces,
          name: sidebarStore?.name,
        })
      );
    }
    // const
  };

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <VaWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <h2>Stores</h2>
        </Box>
        <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 1 }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            flexWrap='wrap'
          >
            <SearchBar
              placeholder='Name'
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              text='+ Add Store'
              className='contained w-100'
              type='submit'
              onClick={() => setAddStore(true)}
            />
          </Stack>
        </Box>
        <Grid conatiner='true'>
          <Grid item md={12}>
            <SimpleTable
              rows={rowData}
              dropDownData={data}
              totalRows={total}
              onChange={pageChange}
              pageLimit={pageLimit}
              pageNumber={pageNumber}
              columns={columns}
              className='table-height'
              selectLabel={"page"}
              loading={loading}
            />
          </Grid>
        </Grid>
        <BasicModal
          modalToggle={modalOpen}
          handleClose={() => setModalOpen(false)}
        >
          <Box sx={style}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              p={4}
            >
              {/* <img src={CrossSvg} alt="no-icon" />   */}
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              p={2}
              alignItems='center'
            >
              <h1>Are you sure?</h1>
              <p className='text-center'>
                Do you really want to delete this store? This action will not be
                undone.
              </p>
              {loading ? <Loading /> : ""}
            </Box>
            <Divider />
            <Box
              display='flex'
              justifyContent='center'
              columnGap={3}
              p={2}
              alignItems='center'
            >
              <Button
                text='cancel'
                onClick={() => setModalOpen(false)}
                varient='outlined'
                className='outlined'
              />
              <Button
                text='Delete'
                onClick={() => removeStore()}
                varient='contained'
                className='modal-contained'
              />
            </Box>
          </Box>
        </BasicModal>
        <BasicModal modalToggle={assignStore}>
          <Box sx={closeStyle}>
            <Box p={2}>
              <h4 className='m-0'>Assign Store</h4>
            </Box>
            <Divider />
            <Box p={2}>
              <label>Select Store</label>
              <Select sx={{ width: "100%" }} value={"Select Store"} />
            </Box>
            <Divider />
            <Box
              display='flex'
              justifyContent='center'
              columnGap={3}
              p={2}
              alignItems='center'
            >
              <Button
                text='cancel'
                onClick={() => setAssignStore(false)}
                varient='outlined'
                className='outlined'
              />
              <Button
                text='Save'
                varient='contained'
                className='modal-contained'
              />
            </Box>
          </Box>
        </BasicModal>
        <BasicModal modalToggle={addStore}>
          <Box sx={closeStyle}>
            <Box p={2}>
              <h4>Add Store</h4>
            </Box>
            <Divider />
            <Box p={2}>
              <Box>
                <Select
                  dropDownData={[
                    { label: "North America", value: "na" },
                    { label: "Europe", value: "eu" },
                    { label: "Asia", value: "fe" },
                  ]}
                  handleChange={(event) =>
                    handleRegionChange(event.target.value)
                  }
                  value={region}
                  sx={{ width: 200 }}
                />
              </Box>
              {!spapiConnect && (
                <Box>
                  <label>Enter Store Name</label>
                  <LabelInput
                    label=''
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                  {/* {!err ? <small style={styles.textSuccess}>{ }</small> : <small style={styles.textDanger}>{err}</small>} */}
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              display='flex'
              justifyContent='center'
              columnGap={3}
              p={2}
              alignItems='center'
            >
              <Button
                text='cancel'
                onClick={() => setAddStore(false)}
                varient='outlined'
                className='outlined'
              />
              <Button
                text='Connect'
                varient='contained'
                className='modal-contained'
                onClick={(e) => {
                  if (spapiConnect) {
                    //handleConnect();
                  } else ConnectAmazonAdvertisingAPI(e, storeName);
                }}
              />
            </Box>
          </Box>
        </BasicModal>
        <div>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            PaperProps={{
              sx: { width: "45%" },
            }}
            onClose={toggleDrawer("right", false)}
          >
            {!editStore ? (
              <>
                <Box
                  p={3}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                >
                  <Typography className='upper-heading'>Store Name</Typography>
                  <Input
                    value={sidebarStore?.name}
                    onChange={(e) => handleStoreChange(e.target.value)}
                  />
                  <Box
                    p={3}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Button
                      text='Save'
                      onClick={() => handleStoreNameChange(sidebarStore)}
                      varient='contained'
                      className='modal-contained'
                    />
                  </Box>
                </Box>
                <Divider />
                {sidebarStore?.status != "Connected" ? (
                  <Box
                    p={3}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Button
                      text='Edit Marketplaces'
                      varient='contained'
                      className='modal-contained'
                      onClick={() => setEditStore(true)}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Box p={3}>
                {" "}
                <EditStore />{" "}
              </Box>
            )}
          </Drawer>
        </div>
      </VaWrapper>
    </>
  );
};

export default Index;
